<template>
	<div>
		<!-- Players will injected here -->
		<v-row no-gutters id="agora-layout-container"></v-row>

		<!-- Video call controls (show only if call has started) -->
		<v-row
			align="center"
			justify="center"
			v-if="alreadyJoined && !isDummyWindow"
		>
			<div id="video-player-button-group-wr" class="text-center">
				<!-- FIXME:: Hide for now (Start or Stop Recording) -->
				<!-- <v-btn
					fab
					color="white"
					large
					class="mx-2"
					@click="
						isRecordingStarted ? stopCallRecording() : startCallRecording()
					"
					v-tooltip="{
						content: isRecordingStarted
							? $t('app.agora.stop_recording.title')
							: $t('app.agora.start_recording.title'),
						placement: 'top',
						targetClasses: ['it-has-a-tooltip'],
						classes: ['agora'],
					}"
				>
					<v-icon dark :color="isRecordingStarted ? 'red' : null">
						{{
							isRecordingStarted
								? $vuetify.icons.values.stop_recording
								: $vuetify.icons.values.start_recording
						}}
					</v-icon>
				</v-btn> -->

				<!-- Mute and Unmute -->
				<v-btn
					fab
					color="white"
					large
					class="mx-2"
					@click="toggleMute()"
					v-tooltip="{
						content: alreadyMuted
							? $t('app.turn_on_mic')
							: $t('app.turn_off_mic'),
						placement: 'top',
						targetClasses: ['it-has-a-tooltip'],
						classes: ['agora'],
					}"
				>
					<v-icon dark :color="alreadyMuted ? 'red' : null">
						{{ $vuetify.icons.values[alreadyMuted ? "mic_off" : "mic"] }}
					</v-icon>
				</v-btn>

				<!-- Screen Share or Stop Sharing -->
				<v-btn
					fab
					color="white"
					large
					class="mx-2"
					@click="alreadyShared ? stopScreenSharing() : shareScreen()"
					v-tooltip="{
						content: alreadyShared
							? $t('app.agora.stop_screen_sharing.title')
							: $t('app.agora.start_screen_share.title'),
						placement: 'top',
						targetClasses: ['it-has-a-tooltip'],
						classes: ['agora'],
					}"
				>
					<v-icon dark :color="alreadyShared ? 'red' : null">
						{{
							alreadyShared
								? $vuetify.icons.values.stop_screen_sharing
								: $vuetify.icons.values.share_screen
						}}
					</v-icon>
				</v-btn>

				<!-- Hang up call -->
				<v-btn
					fab
					dark
					large
					color="error"
					class="mx-2"
					@click="endVideoCall()"
					v-tooltip="{
						content: $t('app.agora.leave_call.title'),
						placement: 'top',
						targetClasses: ['it-has-a-tooltip'],
						classes: ['agora'],
					}"
				>
					<v-icon dark>$vuetify.icons.values.hang_phone</v-icon>
				</v-btn>
			</div>
		</v-row>
	</div>
</template>

<script>
import RTCClient from "@/services/agora/rtc_client";
import { mapState } from "vuex";

export default {
	name: "AgoraIndexPage",
	data() {
		return {
			rtc_client_obj: null,
		};
	},

	created() {
		this.rtc_client_obj = new RTCClient();
	},

	mounted() {
		this.startVideoCall();
	},

	computed: {
		alreadyJoined() {
			return this.rtc_client_obj.joined;
		},
		isRecordingStarted() {
			return this.rtc_client_obj.recording.sid ? true : false;
		},
		isDummyWindow() {
			return this.$route.params.dummy_window.toString() === "true";
		},
		alreadyShared() {
			return this.rtc_client_obj.screenShared;
		},
		alreadyMuted() {
			return this.rtc_client_obj.audioLevel === 0;
		},
	},

	methods: {
		async startVideoCall() {
			try {
				this.$loader.start();
				await this.rtc_client_obj.startVideoCall();
			} catch (error) {
				this.$announce.error(error);
				await this.endVideoCall();
			} finally {
				this.$loader.stop();
			}
		},

		async endVideoCall() {
			try {
				this.$loader.start();

				// Before end call, check if user has shared the screen then stop it.
				if (this.alreadyShared) {
					await this.updateScreenSharingStatus(0);
				}

				await this.rtc_client_obj.endVideoCall();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async shareScreen() {
			try {
				this.$loader.start();

				// Check if its already shraed by someone in the same channel
				let response = await this.$store.dispatch(
					"agora/getScreenSharingStatus",
					{
						channel_name: this.rtc_client_obj.options.channel,
					},
				);
				if (response.status == 1) {
					// This means someone is sharing screen
					throw new Error("app.ntfy.err.cannot_share_screen", {
						cause: "werCustom",
					});
				}

				// Start screen sharing
				await this.rtc_client_obj.shareScreen();

				/**
				 * Update screen sharing status to 1 which
				 * means no other can share screen at the same time.
				 */
				await this.updateScreenSharingStatus(1);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async stopScreenSharing() {
			try {
				this.$loader.start();
				await this.rtc_client_obj.stopScreenSharing();

				await this.updateScreenSharingStatus(0);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		updateScreenSharingStatus(status) {
			/**
			 * Update screen sharing status to 0 (screen sharing is allowed)
			 * or 1 (screen sharing is not allowed)
			 */
			this.$store.dispatch("agora/updateScreenSharingStatus", {
				channel_name: this.rtc_client_obj.options.channel,
				status,
			});
		},

		async startCallRecording() {
			try {
				this.$loader.start();
				// Before start check if only single user is available
				if (this.isSingleUserInCall()) {
					throw new Error("app.ntfy.err.cannot_record_single_user", {
						cause: "werCustom",
					});
				}
				await this.rtc_client_obj.startCallRecording();
			} catch (error) {
				this.$announce.error(error);
				await this.stopCallRecording(error);
			} finally {
				this.$loader.stop();
			}
		},

		async stopCallRecording(error = null) {
			try {
				this.$loader.start();
				await this.rtc_client_obj.stopCallRecording();

				if (!error) {
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		isSingleUserInCall() {
			let users = document.getElementsByClassName("agora-camera-container");
			return users.length == 1;
		},

		toggleMute() {
			try {
				this.rtc_client_obj.toggleMute();
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	async beforeDestroy() {
		// Reset agora state
		this.$store.commit("agora/RESET_STATE");
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/agora.scss";
@import "@/assets/styles/tooltip.scss";
</style>

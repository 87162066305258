var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":"","id":"agora-layout-container"}}),(_vm.alreadyJoined && !_vm.isDummyWindow)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-center",attrs:{"id":"video-player-button-group-wr"}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content: _vm.alreadyMuted
						? _vm.$t('app.turn_on_mic')
						: _vm.$t('app.turn_off_mic'),
					placement: 'top',
					targetClasses: ['it-has-a-tooltip'],
					classes: ['agora'],
				}),expression:"{\n\t\t\t\t\tcontent: alreadyMuted\n\t\t\t\t\t\t? $t('app.turn_on_mic')\n\t\t\t\t\t\t: $t('app.turn_off_mic'),\n\t\t\t\t\tplacement: 'top',\n\t\t\t\t\ttargetClasses: ['it-has-a-tooltip'],\n\t\t\t\t\tclasses: ['agora'],\n\t\t\t\t}"}],staticClass:"mx-2",attrs:{"fab":"","color":"white","large":""},on:{"click":function($event){return _vm.toggleMute()}}},[_c('v-icon',{attrs:{"dark":"","color":_vm.alreadyMuted ? 'red' : null}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values[_vm.alreadyMuted ? "mic_off" : "mic"])+" ")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content: _vm.alreadyShared
						? _vm.$t('app.agora.stop_screen_sharing.title')
						: _vm.$t('app.agora.start_screen_share.title'),
					placement: 'top',
					targetClasses: ['it-has-a-tooltip'],
					classes: ['agora'],
				}),expression:"{\n\t\t\t\t\tcontent: alreadyShared\n\t\t\t\t\t\t? $t('app.agora.stop_screen_sharing.title')\n\t\t\t\t\t\t: $t('app.agora.start_screen_share.title'),\n\t\t\t\t\tplacement: 'top',\n\t\t\t\t\ttargetClasses: ['it-has-a-tooltip'],\n\t\t\t\t\tclasses: ['agora'],\n\t\t\t\t}"}],staticClass:"mx-2",attrs:{"fab":"","color":"white","large":""},on:{"click":function($event){_vm.alreadyShared ? _vm.stopScreenSharing() : _vm.shareScreen()}}},[_c('v-icon',{attrs:{"dark":"","color":_vm.alreadyShared ? 'red' : null}},[_vm._v(" "+_vm._s(_vm.alreadyShared ? _vm.$vuetify.icons.values.stop_screen_sharing : _vm.$vuetify.icons.values.share_screen)+" ")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
					content: _vm.$t('app.agora.leave_call.title'),
					placement: 'top',
					targetClasses: ['it-has-a-tooltip'],
					classes: ['agora'],
				}),expression:"{\n\t\t\t\t\tcontent: $t('app.agora.leave_call.title'),\n\t\t\t\t\tplacement: 'top',\n\t\t\t\t\ttargetClasses: ['it-has-a-tooltip'],\n\t\t\t\t\tclasses: ['agora'],\n\t\t\t\t}"}],staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"error"},on:{"click":function($event){return _vm.endVideoCall()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("$vuetify.icons.values.hang_phone")])],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }